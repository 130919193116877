export const MOMENT_DATE_TIME_FORMAT = "YYYY-MM-DD[T]HH:mm";
export const MOMENT_DATE_FORMAT = "YYYY-MM-DD";
export const MOMENT_TIME_FORMAT = "HH:mm";
export const PRETTY_MOMENT_DATE_TIME_FORMAT = "MMMM Do YYYY, h:mm a";
import moment from "moment-timezone";
import { COLORS } from "./Colors";

/** Note: This returns arrays suitable for react-native-select-picker */
export const timezoneOptions: { label: string; value: string; key: string; initials: string; bgColor: COLORS }[] = moment.tz
  .names()
  .map((timezone: string) => {
    let zoneAbbr = moment.tz([2017, 10], timezone).zoneAbbr();
    if (zoneAbbr.match(/^[+-]?\d+$/)) {
      zoneAbbr = "";
    }
    return {
      label: timezone.replace(/_/g, " ") + (zoneAbbr ? ` (${zoneAbbr})` : ""),
      value: timezone,
      key: timezone,
      bgColor: COLORS.blue,
      initials: zoneAbbr
    };
  })
  .reverse();

// i18n certified - complete
