export enum GIPHY_URLS {
  liveGameMode = "https://firebasestorage.googleapis.com/v0/b/ollie-app-95ed8.appspot.com/o/public%2Fwhats_new%2F2020-07-20%2Flive-game-mode.gif?alt=media",
  liveGameMode2 = "https://media.giphy.com/media/sciPEAktCA4omghBxV/giphy.gif",
  video = "https://media.giphy.com/media/hvkemMn1KHtu7YUldH/giphy.gif",
  video2 = "https://media.giphy.com/media/QAnGppO6Zkx5ibU2ke/giphy.gif",
  covid = "https://firebasestorage.googleapis.com/v0/b/ollie-app-95ed8.appspot.com/o/public%2Fwhats_new%2F2020-07-20%2Fcovid-update-text-on-yes.gif?alt=media",
  maxPreps = "https://firebasestorage.googleapis.com/v0/b/ollie-app-95ed8.appspot.com/o/public%2Fwhats_new%2F2020-07-20%2Fmax-preps.gif?alt=media",
  attendance = "https://firebasestorage.googleapis.com/v0/b/ollie-app-95ed8.appspot.com/o/public%2Fwhats_new%2F2020-07-20%2Fcovid-update-text-on-yes.gif?alt=media",
  checklists = "https://media.giphy.com/media/eIfN5FXkNrf1eaOPfg/giphy.gif",
  shootoutScore = "https://media.giphy.com/media/Us5uuAQjwDWh2XWHKh/giphy.gif",
  deleteMessage = "https://media.giphy.com/media/PuUcqJGEpe9V1NZoAC/giphy.gif",
  queueSubs2 = "https://media.giphy.com/media/JOFn5Yl77o6TthF51U/giphy.gif",
  queueSubs1 = "https://media.giphy.com/media/zPWfCccn5qJrMS1La4/giphy.gif",
  mediaGallery = "https://media.giphy.com/media/1Mj3BNt4tBnFBjuiKp/giphy.gif",
  stats = "https://media.giphy.com/media/oQH2i3c7IfKqKk73qD/giphy.gif",
  chat = "https://media.giphy.com/media/IChheB52zCKsd5tWfJ/giphy.gif",
  teams = "https://media.giphy.com/media/NrFNZ0oSEE1mEEZcpS/giphy.gif",
  readHistory = "https://media.giphy.com/media/5zx7VfTl13JXVrnKT6/giphy.gif",
  basicYellowCard = "https://media.giphy.com/media/35hCe3qOgtf203Uxid/giphy.gif",
  basicTutorial = "https://media.giphy.com/media/Zwn5CgyJIfEFadssUw/giphy.gif",
  basicPassing = "https://media.giphy.com/media/vlPPCe0g3zsWRadouG/giphy.gif",
  basicOutOfBounds = "https://media.giphy.com/media/gx3kFuha28886AjdND/giphy.gif",
  basicGoal = "https://media.giphy.com/media/UvMrVCBXceALhtIuB7/giphy.gif",
  basicFoul = "https://media.giphy.com/media/nVB9SdJFGyMYblIIT8/giphy.gif",
  advancedYellowCard = "https://media.giphy.com/media/3r9TYEyzzQFscZQZsj/giphy.gif",
  advancedTutorial = "https://media.giphy.com/media/x9zXs3Ioi4DBeLm6N9/giphy.gif",
  advancedOutOfBounds = "https://media.giphy.com/media/JoXs8a86GoVxYDrmvL/giphy.gif",
  advancedOffside = "https://media.giphy.com/media/0OaU1vz5CXuK2CFxWo/giphy.gif",
  advancedFoul = "https://media.giphy.com/media/2jQVcPuE5oLOY93aZU/giphy.gif",
  advancedPassing = "https://media.giphy.com/media/OJC07VAodIBu6qvY6B/giphy.gif",
  advancedGoal = "https://media.giphy.com/media/KgKCU8AQi5GiqmXmWX/giphy.gif",
  helpCenter = "https://media.giphy.com/media/0uX4hTcnZZzxBd8Lfa/giphy.gif",
  teamColors = "https://media.giphy.com/media/1RyszYjYdUjMzhO7rQ/giphy.gif",
  conversationTitle = "https://media.giphy.com/media/bbnvFoA0cbW0xfBSFr/giphy.gif",
  viewStatsDuringGame = "https://media.giphy.com/media/nz1adCa8IaOYZCkYZY/giphy.gif",
  addEditScore = "https://media.giphy.com/media/Czl6tW8NTCZPL1wjmx/giphy.gif",
  coachesShare = "https://media.giphy.com/media/dxclU2ideOSnJgjwMe/giphy.gif",
  socialShare = "https://media.giphy.com/media/79kEMiKHIAtCosSp2j/giphy.gif",
  switchFieldSide = "https://media.giphy.com/media/rYDlYeNAnRRKbwuIGx/giphy.gif",
  disableMVPs = "https://media.giphy.com/media/vK6FQ2yuAXngqp9OWf/giphy.gif",
  customizeEventColors = "https://media.giphy.com/media/81VuWGZOuTD0MqHxAQ/giphy.gif",
  createEventUpdates = "https://media.giphy.com/media/jHVzhPhLbb6dyKFUkA/giphy.gif",
  attendanceReminderImprovements = "https://media.giphy.com/media/S6qUN00YCJU5X00r3S/giphy.gif",
  trends = "https://media.giphy.com/media/epdNAY5gThE9LqFjmh/giphy.gif",
  disableCovidCheck = "https://media.giphy.com/media/M13cl5jLZeNtf1NFUh/giphy.gif",
  teamLogo = "https://media.giphy.com/media/XE1Vq9eefeQDgrIoyN/giphy.gif",
  archiveConversations = "https://media.giphy.com/media/pzmhJpJb3tDgtpcd9p/giphy.gif",
  polls = "https://media.giphy.com/media/eD6WgSf7TRRBYfNY76/giphy.gif",
  eventReminders = "https://media.giphy.com/media/iUAv9kJv4aE35k5uHd/giphy.gif",
  checklistsPublic = "https://media.giphy.com/media/kaMjFwNIkoqWmFl0ti/giphy.gif",
  evalTemplates = "https://media.giphy.com/media/07nGntAYQywbGCivjc/giphy.gif?cid=790b76119cdeb14a768996581f5d6b5493fb392098f526a7&rid=giphy.gif&ct=g",
  evalComplete = "https://media.giphy.com/media/MhVQxGooaGt49vb3dP/giphy.gif?cid=790b76113efb6e8e2d066731bdccc43a5bc713da9c9a7ab9&rid=giphy.gif&ct=g",
  attendanceReports = "https://media.giphy.com/media/2jRXhRKgABKtdhrh1b/giphy.gif?cid=790b761196078d221d59beab53955c9d65487654fa2eab8f&rid=giphy.gif&ct=g",
  illegalThrowIn = "https://media.giphy.com/media/IWLMfaAe8OcF0IL4Lp/giphy.gif?cid=790b7611bb670571711767e5bb6b6ce01f2201ce1977129b&rid=giphy.gif&ct=g",
  indirectFreeKick = "https://media.giphy.com/media/rKKWXlYkWy1JFOeKIf/giphy.gif?cid=790b7611b6dd93f7d4fd04f5fca1fbe79293dd27dbf16b2c&rid=giphy.gif&ct=g",
  jerseyPresets = "https://media.giphy.com/media/N1uMiWhhLlhXFDFWv2/giphy.gif?cid=790b76117532510dc5993b92da65f8f5403439b41b4ba5b3&rid=giphy.gif&ct=g",
  sortPlayers = "https://media.giphy.com/media/usHiK5nCrVXCCq1kkP/giphy.gif?cid=790b76118e96abdfed887771d4fe149c24f0e271ef7800cc&rid=giphy.gif&ct=g",
  exportRoster = "https://media.giphy.com/media/qGAD6QXlkU9zDF9Hj9/giphy.gif?cid=790b76118b273becd0c6360f6df13ee716392afb3d22cbda&rid=giphy.gif&ct=g",
  stopClockMode1 = "https://media.giphy.com/media/RzfN8FsMGbmvMUgNWI/giphy.gif?cid=790b7611a958edf984a2ab3b2c727f568f4a0b8b52c7810f&rid=giphy.gif&ct=g",
  stopClockMode2 = "https://media.giphy.com/media/sq8WQHtDLddwJDyZJO/giphy.gif?cid=790b7611567b380bcc029ff3ca4694aacffb0df646463c89&rid=giphy.gif&ct=g",
  pinConversation = "https://media.giphy.com/media/c3oRM5NUbZpJCDMknM/giphy.gif",
  coachesEvals = "https://media.giphy.com/media/EtMSHBg9Hdtd4JfANb/giphy.gif",
  resourceLibrary = "https://media.giphy.com/media/3PAxTCj47jg2OrkdsN/giphy.gif",
  communicationLocale = "https://media.giphy.com/media/SSX5d1bHDL1sUcgMCI/giphy.gif",
  chatSearch = "https://media.giphy.com/media/eLYRVmikRPEgJG0e6R/giphy.gif",
  otherSports = "https://media.giphy.com/media/y1YOvOymHdp37KP6zm/giphy.gif",
  fundraising = "https://media.giphy.com/media/FquM2pdkXedXlX2SL5/giphy.gif",
  clubEvents = "https://media.giphy.com/media/HY2cfKtSgnM78S96E5/giphy.gif",
  orgTeamTags = "https://media.giphy.com/media/v1.Y2lkPTc5MGI3NjExYjdkZDJhNjlhNGVjYmVjMzdjYjI0YzE0ODBjOGQ3NzhiZTkzNGEzMyZjdD1n/xWKrdqOov6clpKa46x/giphy.gif",
  customClubConversation = "https://media.giphy.com/media/v1.Y2lkPTc5MGI3NjExYjU2NjMxNWY5MWUxZTdiOTY5NDA2M2M3Zjk4ODk1MmU1M2I2OWE1NiZjdD1n/gu93RtgNBOHD3ey9TC/giphy.gif",
  multiImageUpload = "https://media.giphy.com/media/v1.Y2lkPTc5MGI3NjExNDNzMGNhMGtmZGoza3I2dGFqZDBoNWx5em8xdTdsb2JnM2dxZWF3aCZlcD12MV9pbnRlcm5hbF9naWZfYnlfaWQmY3Q9Zw/5XTNWjUwk2nplvi8g1/giphy.gif",
  blockAndMute = "https://media.giphy.com/media/v1.Y2lkPTc5MGI3NjExNzR5OXZiMjh4NDNtOGJqbjFzODFhbzZtd3Rkc291cXY3bDV4cXlvcyZlcD12MV9pbnRlcm5hbF9naWZfYnlfaWQmY3Q9Zw/fXbmdYRpKBSZu2u3sn/giphy.gif",
  disableReplies = "https://media.giphy.com/media/v1.Y2lkPTc5MGI3NjExbTI2amI3bzZ4aHZmZWdxMDQ0azhkZ2dhZzlzdWI3bTZwanVjNWRrOCZlcD12MV9pbnRlcm5hbF9naWZfYnlfaWQmY3Q9Zw/mc9DrMBm9a0GpthDxR/giphy.gif"
}

// i18n certified - complete
