import { getNameList } from "country-list";
import provinces from "provinces";
import _ from "lodash";

/** Note: This returns arrays suitable for react-native-select-picker */

export const provincesByCountryCode = provinces.reduce((acc: any, prov: any) => {
  acc[prov.country] = acc[prov.country] || [];
  acc[prov.country].push({
    label: prov.name,
    value: prov.short ?? prov.name,
    key: prov.short ?? prov.name
  });
  return acc;
}, {});

const countryMap = getNameList();

export function getCountries(p: { locale: string }) {
  return [{ label: "United States of America", value: "US", key: "US" }].concat(
    _.orderBy(
      Object.keys(countryMap).reduce((acc, prettyName) => {
        if (countryMap[prettyName] !== "US") {
          acc.push({
            label: capitalize(prettyName),
            value: countryMap[prettyName],
            key: countryMap[prettyName]
          });
        }

        return acc;
      }, [] as { label: string; value: string; key: string }[]),
      "label"
    )
  );
}

function capitalize(str: string) {
  return str.replace(/(^|\s)\S/g, (l: string) => l.toUpperCase());
}

// i18n certified - complete
