import { translate } from "@ollie-sports/i18n";
import { FundraisingPrize, PRIZE_TYPES } from "@ollie-sports/models";

export function GET_PRIZE_TYPE_DATA(locale: string): Record<
  PRIZE_TYPES,
  {
    prettyTitle: string;
    isTeamAllowedToCustomize: boolean;
  }
> {
  return {
    player_amount: {
      isTeamAllowedToCustomize: true,
      prettyTitle: translate({
        defaultMessage: "Player Amount",
        description: "A title for a fundraising prize based on player amount raised",
        serverLocale: locale
      })
    },
    team_amount: {
      isTeamAllowedToCustomize: true,
      prettyTitle: translate({
        defaultMessage: "Team Goal",
        description: "A title for a fundraising prize based on team amount raised",
        serverLocale: locale
      })
    },
    page_views: {
      isTeamAllowedToCustomize: true,
      prettyTitle: translate({
        defaultMessage: "Player Page Views",
        description: "A title for a fundraising prize based on page views",
        serverLocale: locale
      })
    },
    player_rank_on_team: {
      isTeamAllowedToCustomize: true,
      prettyTitle: translate({
        defaultMessage: "Top Player(s)",
        description: "A title for a fundraising prize based on top players on the team",
        serverLocale: locale
      })
    },
    player_ranking_on_org: {
      isTeamAllowedToCustomize: false,
      prettyTitle: translate({
        defaultMessage: "Top Player(s) in Org",
        description: "A title for a fundraising prize based on top players in the org",
        serverLocale: locale
      })
    },
    team_ranking_on_org: {
      isTeamAllowedToCustomize: false,
      prettyTitle: translate({
        defaultMessage: "Top Team(s) in Org",
        description: "A title for a fundraising prize based on top team in the org",
        serverLocale: locale
      })
    },
    referrals: {
      isTeamAllowedToCustomize: false,
      prettyTitle: translate({
        defaultMessage: "Referrals",
        description: "A title for a fundraising prize based on referrals",
        serverLocale: locale
      })
    }
  };
}
export const ReferralPrize3Referrals: FundraisingPrize = {
  amount: 3,
  description: "1 raffle ticket for 3 referrals",
  isCustom: false,
  name: "1 Raffle Ticket",
  prizeId: 999999999998,
  type: PRIZE_TYPES.referrals
};
export const ReferralPrize6Referrals: FundraisingPrize = {
  amount: 6,
  description: "2 raffle ticket for 3 referrals",
  isCustom: false,
  name: "2 Raffle Tickets",
  prizeId: 999999999999,
  type: PRIZE_TYPES.referrals
};
