import { translate } from "@ollie-sports/i18n";
import { OrgRegistrationStatus, OverallInvoiceStatus } from "@ollie-sports/models";

export const PRETTY_OVERALL_ORG_INVOICE_STATUS: (locale: string) => Record<OverallInvoiceStatus, string> = locale => ({
  [OverallInvoiceStatus.paid]: translate.common.Paid,
  [OverallInvoiceStatus.invoiceFailedPayment]: translate.common.FailedPayment,
  [OverallInvoiceStatus.inProgress]: translate.common.InProgress,
  [OverallInvoiceStatus.outstanding]: translate({
    defaultMessage: "Outstanding",
    description: "As in an invoice is outstanding"
  }),
  [OverallInvoiceStatus.late]: translate({ defaultMessage: "Late", description: "As in a late payment" }),
  [OverallInvoiceStatus.latePaymentInstallment]: translate({
    defaultMessage: "Late Payment Installment",
    description: "As in a late payment installment of a payment plan"
  })
});
