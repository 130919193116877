import { translate } from "@ollie-sports/i18n";
import { OrgRegistrationStatus } from "@ollie-sports/models";

export const PRETTY_ORG_REGISTRATION_STATUS: (locale: string) => Record<OrgRegistrationStatus, string> = locale => ({
  [OrgRegistrationStatus.bad]: translate({
    defaultMessage: "Past Due/Problems",
    description: "When a registration needs attention because something is wrong",
    serverLocale: locale
  }),
  [OrgRegistrationStatus.registered]: translate({ defaultMessage: "Registered", serverLocale: locale }),
  [OrgRegistrationStatus.incomplete]: translate({ defaultMessage: "In Progress", serverLocale: locale }),
  [OrgRegistrationStatus.unregistered]: translate({ defaultMessage: "Unregistered", serverLocale: locale }),
  [OrgRegistrationStatus["no-registration-needed"]]: translate({
    defaultMessage: "No Registration Required",
    serverLocale: locale
  }),
  [OrgRegistrationStatus["team-not-ready"]]: translate({ defaultMessage: "Team Not Ready", serverLocale: locale })
});
