import { getUniversalHelpers } from "../helpers";
import {
  SoccerGameRTDBInfo,
  SystemStatus,
  DeviceRegistration,
  SimpleHeartBeat,
  RealTimeNotification,
  AccountId,
  ReadHistoryItem
} from "@ollie-sports/models";
import Emittery from "emittery";
import type firebase from "firebase/compat";

export const RTBDRefs = {
  getSystemStatusRef() {
    return getUniversalHelpers().app.database().ref("/systemStatus") as unknown as TypedFirebaseObjectRef<SystemStatus>;
  },
  getDeviceRegistrationRef(p: { accountId: string }) {
    return getUniversalHelpers().app.database().ref(`/deviceRegistrationV2/${p.accountId}/`) as unknown as TypedFirebaseObjectRef<
      Record<string, DeviceRegistration>
    >;
  },
  getSimpleHeartbeatRef(p: { accountId: string }) {
    return getUniversalHelpers().app.database().ref(`/simpleHeartBeatV2/${p.accountId}/`) as unknown as TypedFirebaseObjectRef<
      Record<string, SimpleHeartBeat>
    >;
  },
  getAccountSimpleNotificationRef(p: { accountId: string }) {
    return getUniversalHelpers()
      .app.database()
      .ref(`/simpleNotification/${p.accountId}`) as unknown as TypedFirebaseRecordRef<RealTimeNotification>;
  },
  getAccountSimpleNotificationDeepRef(p: { accountId: string; notificationId: string }) {
    return getUniversalHelpers()
      .app.database()
      .ref(`/simpleNotification/${p.accountId}/${p.notificationId}`) as unknown as TypedFirebaseObjectRef<RealTimeNotification>;
  },
  getSoccerGameInfoRef(p: { soccerGameId: string }) {
    return getUniversalHelpers()
      .app.database()
      .ref(`/soccerGameInfo/${p.soccerGameId}`) as unknown as TypedFirebaseObjectRef<SoccerGameRTDBInfo>;
  },
  getConversationTypingRef(p: { conversationId: string }) {
    return getUniversalHelpers()
      .app.database()
      .ref(`/conversationTyping/${p.conversationId}`) as unknown as TypedFirebaseRecordRef<Record<AccountId, number>>;
  },
  getConversationTypingByAccountRef(p: { conversationId: string; accountId: string }) {
    return getUniversalHelpers()
      .app.database()
      .ref(`/conversationTyping/${p.conversationId}/${p.accountId}`) as unknown as TypedFirebasePrimitiveRef<number>;
  },
  getMessageReadHistoryRef(p: { conversationId: string; messageId: string }) {
    return getUniversalHelpers()
      .app.database()
      .ref(`/conversationReadHistory/${p.conversationId}/${p.messageId}`) as unknown as TypedFirebaseRecordRef<ReadHistoryItem>;
  }
};

type TypedFirebasePrimitiveRef<Primitive extends string | number | boolean> = Omit<
  firebase.database.Reference,
  "set" | "once" | "on"
> & {
  set: (o: Primitive) => ReturnType<firebase.database.Reference["set"]>;
  once: () => Promise<TypedSnapshot<Primitive>>;
  on: (t: "value", sub: (snap: TypedSnapshot<Primitive>) => void) => ReturnType<firebase.database.Reference["on"]>;
};

type TypedFirebaseObjectRef<Obj extends {}> = Omit<firebase.database.Reference, "set" | "update" | "once" | "on"> & {
  set: (o: Obj) => ReturnType<firebase.database.Reference["set"]>;
  update: (o: Partial<Obj>) => ReturnType<firebase.database.Reference["update"]>;
  once: (type: "value") => Promise<TypedSnapshot<Obj>>;
  on: (t: "value", sub: (snap: TypedSnapshot<Obj>) => void) => ReturnType<firebase.database.Reference["on"]>;
};

type TypedFirebaseRecordRef<Obj extends {}> = Omit<firebase.database.Reference, "child" | "on"> & {
  child: (str: string) => TypedFirebaseObjectRef<Obj>;
  once: (type: "value") => Promise<TypedSnapshot<Record<string, Obj>>>;
  on:
    | ((
        str: "child_added" | "child_removed" | "child_removed",
        sub: (snap: TypedSnapshot<Obj>) => void
      ) => ReturnType<firebase.database.Reference["on"]>)
    | ((str: "value", sub: (snap: TypedSnapshot<Record<string, Obj>>) => ReturnType<firebase.database.Reference["on"]>) => void);
};

type TypedSnapshot<Obj extends {}> = Omit<firebase.database.DataSnapshot, "val"> & {
  val: () => Obj | undefined;
};

//Note: The below is a poor man's firestore lift for the realtime database...
//Note: This hasn't been used or tested. I created it for a use case that I turned out to not be necessary. Leaving it here just in case.
type Unsubscribe = () => void;
type Cache = Record<string, { ee: Emittery; offRTDB: () => void; currentValue: {} | null }>;
function dedupedRefSubscribe<T extends object>(p: {
  ref: TypedFirebaseObjectRef<T>;
  cache: Cache;
  onValue: (d: T) => void;
}): Unsubscribe {
  const refKey = p.ref.toString();

  if (!p.cache[refKey]) {
    const thisEE = new Emittery();

    const fn = p.ref.on("value", snap => {
      const data = snap.val();
      p.cache[refKey].currentValue = data ?? null;
      thisEE.emit("change", data);
    });

    p.cache[refKey] = {
      ee: thisEE,
      currentValue: null,
      offRTDB: () => {
        p.ref.off("value", fn);
      }
    };
  }

  const { ee, offRTDB } = p.cache[refKey];

  const unsub = ee.on("change", data => {
    p.onValue(data);
  });

  return () => {
    unsub();
    if (ee.listenerCount() <= 0) {
      offRTDB();
      delete p.cache[refKey];
    }
  };
}

type SubscriptionVal<T> = { data: T | null; isLoading: boolean };
//Not ideal to pass react, but it's cleaner to have this function here for when we eventually support React in core
export function useRTDBRefSubscription<T extends object>(p: {
  ref: TypedFirebaseObjectRef<T>;
  cache: Cache;
  react: any;
  skip?: boolean;
}): SubscriptionVal<T> {
  const refKey = p.ref.toString();

  const [val, setVal] = p.react.useState({
    data: (p.cache[refKey]?.currentValue as T) ?? null,
    isLoading: !!p.cache[refKey]?.currentValue
  });

  const lastRefKey = p.react.useRef(refKey);
  p.react.useEffect(() => {
    if (p.skip) {
      return;
    }

    let isMounted = true;
    const unsub = dedupedRefSubscribe({
      ref: p.ref,
      cache: p.cache,
      onValue: newVal => {
        isMounted &&
          setVal({
            data: newVal,
            isLoading: false
          });
      }
    });

    //Shouldn't happen often, but if the ref changes, set state to the current value of the new ref
    if (lastRefKey.current !== refKey) {
      lastRefKey.current = refKey;
      setVal({
        data: (p.cache[refKey]?.currentValue as T) ?? null,
        isLoading: !!p.cache[refKey]?.currentValue
      });
    }

    return () => {
      isMounted = false;
      unsub();
    };
  }, [refKey, p.skip]);

  return val as SubscriptionVal<T>;
}

// i18n certified - complete
