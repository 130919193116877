import { translate } from "@ollie-sports/i18n";
import { CardOrACHCodes, OrgPaymentType, OrgRegistrationStatus, OverallInvoiceStatus } from "@ollie-sports/models";

export const PRETTY__ORG_PAYMENT_TYPE: (locale: string) => Record<OrgPaymentType, string> = locale => ({
  [OrgPaymentType.invoiceCredit]: translate.common(locale).Credit,
  [OrgPaymentType.invoiceFailedPayment]: translate.common(locale).FailedPayment,
  [OrgPaymentType.invoiceFailedECheckPayment]: translate.common(locale).FailedPayment,
  [OrgPaymentType.invoiceDefault]: translate.common(locale).Payment,
  [OrgPaymentType.openOrgEventDefault]: `${translate.common(locale).Tryouts}/${translate.common(locale).Camps}`
});

export const PRETTY_PAYMENT_ERROR_CODES: { [key in CardOrACHCodes | string | number]?: (locale: string) => string } = {
  200: locale => translate({ defaultMessage: "Declined - Call card issuer", serverLocale: locale }),
  201: locale => translate({ defaultMessage: "Declined - Call card issuer", serverLocale: locale }),
  202: locale => translate({ defaultMessage: "Insufficient funds", serverLocale: locale }),
  203: locale => translate({ defaultMessage: "Over limit", serverLocale: locale }),
  204: locale => translate({ defaultMessage: "Transaction not allowed by your bank - Call card issuer", serverLocale: locale }),
  220: locale => translate({ defaultMessage: "Incorrect information", serverLocale: locale }),
  221: locale => translate({ defaultMessage: "No such card issuer", serverLocale: locale }),
  222: locale => translate({ defaultMessage: "No card number on file with issuer", serverLocale: locale }),
  223: locale => translate({ defaultMessage: "Expired card", serverLocale: locale }),
  224: locale => translate({ defaultMessage: "Invalid expiration date", serverLocale: locale }),
  225: locale => translate({ defaultMessage: "Invalid CVV", serverLocale: locale }),
  226: locale => translate({ defaultMessage: "Invalid PIN", serverLocale: locale }),
  240: locale => translate({ defaultMessage: "Declined - Call card issuer", serverLocale: locale }),
  250: locale => translate({ defaultMessage: "Lost or stolen card", serverLocale: locale }),
  251: locale => translate({ defaultMessage: "Lost card", serverLocale: locale }),
  252: locale => translate({ defaultMessage: "Stolen card", serverLocale: locale }),
  253: locale => translate({ defaultMessage: "Fraudulent card", serverLocale: locale }),
  260: locale => translate({ defaultMessage: "Declined", serverLocale: locale }),
  261: locale => translate({ defaultMessage: "Declined", serverLocale: locale }),
  262: locale => translate({ defaultMessage: "Declined", serverLocale: locale }),
  263: locale => translate({ defaultMessage: "Declined", serverLocale: locale }),
  264: locale => translate({ defaultMessage: "Declined - Retry in a few days", serverLocale: locale }),
  300: locale => translate({ defaultMessage: "Transaction was rejected by gateway", serverLocale: locale }),
  400: locale => translate({ defaultMessage: "Transaction error returned by processor", serverLocale: locale }),
  410: locale => translate({ defaultMessage: "Invalid merchant configuration", serverLocale: locale }),
  411: locale => translate({ defaultMessage: "Merchant account is inactive", serverLocale: locale }),
  420: locale => translate({ defaultMessage: "Communication error", serverLocale: locale }),
  421: locale => translate({ defaultMessage: "Communication error with issuer", serverLocale: locale }),
  430: locale => translate({ defaultMessage: "Duplicate transaction at processor", serverLocale: locale }),
  440: locale => translate({ defaultMessage: "Processor format error", serverLocale: locale }),
  441: locale => translate({ defaultMessage: "Invalid transaction information", serverLocale: locale }),
  460: locale => translate({ defaultMessage: "Processor feature not available", serverLocale: locale }),
  461: locale => translate({ defaultMessage: "Unsupported card type", serverLocale: locale }),
  R01: locale => translate({ defaultMessage: "Insufficient Funds", serverLocale: locale }),
  R02: locale => translate({ defaultMessage: "Account Closed", serverLocale: locale }),
  R03: locale => translate({ defaultMessage: "No Bank Account/Unable to Locate Account", serverLocale: locale }),
  R04: locale => translate({ defaultMessage: "Invalid Account Number", serverLocale: locale }),
  R05: locale => translate({ defaultMessage: "Unauthorized Debit Corporate SEC Code", serverLocale: locale }),
  R06: locale => translate({ defaultMessage: "Returned per ODFIs Request", serverLocale: locale }),
  R07: locale => translate({ defaultMessage: "Customer Revoked Authorization", serverLocale: locale }),
  R08: locale => translate({ defaultMessage: "Payment Stopped", serverLocale: locale }),
  R09: locale => translate({ defaultMessage: "Uncollected Funds", serverLocale: locale }),
  R10: locale => translate({ defaultMessage: "Not authorized to Debit Receiver's Account", serverLocale: locale }),
  R11: locale => translate({ defaultMessage: "Check Truncation Entry Return", serverLocale: locale }),
  R12: locale => translate({ defaultMessage: "Branch Sold to Another DFI", serverLocale: locale }),
  R13: locale => translate({ defaultMessage: "Invalid ACH Routing Number", serverLocale: locale }),
  R14: locale => translate({ defaultMessage: "Payee Deceased", serverLocale: locale }),
  R15: locale => translate({ defaultMessage: "Beneficiary or Account Holder Deceased", serverLocale: locale }),
  R16: locale => translate({ defaultMessage: "Account Frozen", serverLocale: locale }),
  R17: locale => translate({ defaultMessage: "File Record Edit Criteria", serverLocale: locale }),
  R18: locale => translate({ defaultMessage: "Improper Effective Entry Date", serverLocale: locale }),
  R19: locale => translate({ defaultMessage: "Amount Field Error", serverLocale: locale }),
  R20: locale => translate({ defaultMessage: "Non-Transaction Account", serverLocale: locale }),
  R21: locale => translate({ defaultMessage: "Invalid Company Identification", serverLocale: locale }),
  R22: locale => translate({ defaultMessage: "Invalid Individual ID Number", serverLocale: locale }),
  R23: locale => translate({ defaultMessage: "Credit Entry Refused by Receiver", serverLocale: locale }),
  R24: locale => translate({ defaultMessage: "Duplicate Entry", serverLocale: locale }),
  R25: locale => translate({ defaultMessage: "Addenda Error", serverLocale: locale }),
  R26: locale => translate({ defaultMessage: "Mandatory Field Error", serverLocale: locale }),
  R27: locale => translate({ defaultMessage: "Trace Number Error", serverLocale: locale }),
  R28: locale => translate({ defaultMessage: "Routing Number Check Digit Error", serverLocale: locale }),
  R29: locale => translate({ defaultMessage: "Corporate Customer Advises Not Authorized", serverLocale: locale }),
  R30: locale => translate({ defaultMessage: "Receiving Bank Not Participating in E-Check", serverLocale: locale }),
  R31: locale => translate({ defaultMessage: "Permissible Return Entry (CCD and CTX only)", serverLocale: locale }),
  R32: locale => translate({ defaultMessage: "RDFI Non-Settlement", serverLocale: locale }),
  R33: locale => translate({ defaultMessage: "Return of XCK Entry", serverLocale: locale }),
  R34: locale => translate({ defaultMessage: "Limited Participation DFI", serverLocale: locale }),
  R35: locale => translate({ defaultMessage: "Improper Debit Entry", serverLocale: locale }),
  R36: locale => translate({ defaultMessage: "Improper Credit Entry", serverLocale: locale }),
  R37: locale => translate({ defaultMessage: "Source Document Presented for Payment", serverLocale: locale }),
  R38: locale => translate({ defaultMessage: "Stop Payment on Source Document", serverLocale: locale }),
  R39: locale => translate({ defaultMessage: "Improper Source Document", serverLocale: locale }),
  R40: locale => translate({ defaultMessage: "Return of ENR Entry", serverLocale: locale }),
  R41: locale => translate({ defaultMessage: "Invalid Transaction Code", serverLocale: locale }),
  R42: locale => translate({ defaultMessage: "Routing Number/Check Digit Error", serverLocale: locale }),
  R43: locale => translate({ defaultMessage: "Invalid DFI Account Number", serverLocale: locale }),
  R44: locale => translate({ defaultMessage: "Invalid ID Number", serverLocale: locale }),
  R45: locale => translate({ defaultMessage: "Invalid Individual Name/Company Name", serverLocale: locale }),
  R46: locale => translate({ defaultMessage: "Invalid Representative Payee Indicator", serverLocale: locale }),
  R47: locale => translate({ defaultMessage: "Duplicate Enrollment", serverLocale: locale }),
  R50: locale => translate({ defaultMessage: "State Law Affecting RCK Acceptance", serverLocale: locale }),
  R51: locale => translate({ defaultMessage: "Item Related to RCK Entry Is Improper", serverLocale: locale }),
  R52: locale => translate({ defaultMessage: "Stop Payment on Item Related to RCK Entry", serverLocale: locale }),
  R53: locale => translate({ defaultMessage: "Item and RCK Entry Presented for Payment", serverLocale: locale }),
  R61: locale => translate({ defaultMessage: "Misrouted Return", serverLocale: locale }),
  R62: locale => translate({ defaultMessage: "Return of Erroneous or Reversing Debit", serverLocale: locale }),
  R67: locale => translate({ defaultMessage: "Duplicate Return", serverLocale: locale }),
  R68: locale => translate({ defaultMessage: "Untimely Return", serverLocale: locale }),
  R69: locale => translate({ defaultMessage: "Field Error", serverLocale: locale }),
  R70: locale => translate({ defaultMessage: "Permissible Return Not Accepted", serverLocale: locale }),
  R71: locale => translate({ defaultMessage: "Misrouted Dishonored Return", serverLocale: locale }),
  R72: locale => translate({ defaultMessage: "Untimely Dishonored Return", serverLocale: locale }),
  R73: locale => translate({ defaultMessage: "Timely Original Return", serverLocale: locale }),
  R74: locale => translate({ defaultMessage: "Corrected Return", serverLocale: locale }),
  R75: locale => translate({ defaultMessage: "Original Return Not a Duplicate", serverLocale: locale }),
  R76: locale => translate({ defaultMessage: "No Errors Found", serverLocale: locale }),
  R77: locale => translate({ defaultMessage: "Non-Acceptance of R62", serverLocale: locale }),
  R80: locale => translate({ defaultMessage: "IAT Coding Error", serverLocale: locale }),
  R81: locale => translate({ defaultMessage: "Non-Participant in IAT Program", serverLocale: locale }),
  R82: locale => translate({ defaultMessage: "Invalid Foreign Receiving DFI Identification", serverLocale: locale }),
  R83: locale => translate({ defaultMessage: "Foreign Receiving DFI Unable to Settle", serverLocale: locale }),
  R84: locale => translate({ defaultMessage: "Not Processed by Gateway", serverLocale: locale }),
  R85: locale => translate({ defaultMessage: "Incorrectly Coded Outbound International Payment", serverLocale: locale })
};
